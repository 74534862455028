import React from 'react';
import Link from 'next/link';
import CustomImage from '../CustomImage/CustomImage';

export default function Button({
  btnUrl,
  btnText,
  variant,
  disabledArrows = false,
  style
}) {
  return (
    <Link href={btnUrl} className={`cs-btn cs-style-1 btn_padding ${variant}`} style={{...style}}>
      <span>{btnText}</span>
      {!disabledArrows && (
        <i style={{marginLeft:'0.5rem'}}>
          <CustomImage
            src="/images/icons/arrow_white.svg"
            alt="Icon"
            height={11}
            width={15}
          />
          <CustomImage
            src="/images/icons/arrow_white.svg"
            alt="Icon"
            height={11}
            width={15}
          />
        </i>
      )}
    </Link>
  );
}
